import ItemDetailsPage from "@pages/items/details";
import ItemsListPage from "@pages/items/list";
import { useLocation } from "@reach/router";
import { extractItemRouteInformation, extractItemsRouteInformation } from "@utils/items/UrlFilterParser";
import { navigateTo404 } from "@utils/navigation";

/**
 * @summary This page is only used during debugging, where Cloudflare Workers isn't available to redirect to the proper page. When in production, ItemDetailsPage and ItemsListPage are routed to directly.
 */
export default () => {
    const location = useLocation();
    if(!location)
        return null;

    const itemInformation = extractItemRouteInformation(location.href);
    if(itemInformation) {
        return <ItemDetailsPage route={itemInformation} />;
    }

    const itemsInformation = extractItemsRouteInformation(location.href);
    if(itemsInformation) {
        return <ItemsListPage route={itemsInformation} />;
    }

    return navigateTo404();
};